import React from 'react';
import { Col, Card } from 'react-bootstrap';
import cardImg2 from '../img/Inicio JpMotors.jpg';
import cardImg3 from '../img/Inicio JpMotors 2.jpg';
import cardImg6 from '../img/crecimiento.jpg';

function Nosotros() {
  return (
    <div>
      <br />
      <br />
      {/* Contenedor centrado */}
      <div className="text-center mb-4">
        <h1>JP MOTORS, S. A.</h1>
        <h4>QUIENES SOMOS</h4>
        <p>
          Somos una empresa dedicada a ofrecer productos y servicios de la más alta calidad en la industria automotriz.
        </p>
        <p>Nuetros Objetivos principales:</p>
        <ul className="list-unstyled">
          <li>• Calidad: Nos esforzamos por mantener los más altos estándares en todos nuestros productos y servicios.</li>
          <li>• Servicio al Cliente: Valoramos a nuestros clientes y nos comprometemos a brindarles una atención personalizada y eficiente.</li>
          <li>• Sostenibilidad: Promovemos prácticas sostenibles y responsables con el medio ambiente.</li>
        </ul>
      </div>

      <div className="row">
        <Col sm={6}>
          <Card className="mb-3">
            <div className="row g-0">
              <div className="col-md-12">
                <img 
                  src={cardImg2} 
                  className="img-fluid rounded-start" 
                  alt="Visión" 
                  style={{ height: 'auto', width: '100%', objectFit: 'cover' }} 
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={6}>
          <Card className="mb-3">
            <div className="row g-0">
              <div className="col-md-12">
                <img 
                  src={cardImg3} 
                  className="img-fluid rounded-start" 
                  alt="Misión" 
                  style={{ height: 'auto', width: '100%', objectFit: 'cover' }} 
                />
              </div>
            </div>
          </Card>
        </Col>
      </div>

      <div className="row">
        <Col sm={6}>
          <Card className="mb-3">
            <div className="row g-0">
              <div className="col-md-4">
                <img 
                  src={cardImg6} 
                  className="img-fluid rounded-start" 
                  alt="Actualidad" 
                />
              </div>
              <div className="col-md-8">
                <Card.Body>
                  <Card.Title>Actualidad</Card.Title>
                  <Card.Text>
                    Hoy en día, la empresa JP MOTORS, S. A. es reconocida por su compromiso con la calidad y el excelente servicio al cliente.
                  </Card.Text>
                </Card.Body>
              </div>
            </div>
          </Card>
        </Col>
      </div>

      <div className="text-center mb-4" style={{ padding: '20px' }}>
        <h4 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Productos y Servicios</h4>
        <p style={{ fontSize: '1.5rem' }}>
          Vehículos Nuevos: Mitsubishi, Nissan, Kia, Ford, Fuso, BMW y Mini Cooper.
        </p>
        <p style={{ fontSize: '1.5rem' }}>
          Vehículos Usados: Marcas en general.
        </p>
        <p style={{ fontSize: '1.5rem' }}>
          <strong>Servicios Adicionales:</strong> Accesorios y repuestos para todas las marcas de vehículos.
        </p>
      </div>

    </div>
  );
}

export default Nosotros;
